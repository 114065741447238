import React from "react"
import { FormattedMessage } from "gatsby-plugin-intl"
import Icon from "../../components/Icon"
import { path } from "../../config/routes"
import { stateGenerator } from "../../helpers/application"
import Meta from "../../components/layout/Meta"

const SignUp = () => {
  const state = stateGenerator({
    redirectURL: path("SIGN_UP"),
    stage: "project",
  })
  const GithubInstallationLink = `${process.env.GITHUB_APP_URL}?state=${state}`
  if (typeof window !== "undefined") {
    window.location.href = GithubInstallationLink
  }
  return (
    <>
      <Meta />
      <div
        style={{
          width: "100%",
          height: "100vh",
          display: "flex",
          flex: 1,
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <p>
          <FormattedMessage
            id="pages.github.signup.title"
            defaultMessage="You will be redirected to GitHub shortly. If you're not automatically redirected:"
            description=" Github signup redirection page title"
          />
          <a
            target="_blank"
            href={GithubInstallationLink}
            className="text-gray-500"
          >
            <FormattedMessage
              id="pages.github.signup.link"
              defaultMessage=" Click here"
              description=" Github signup redirection installation link"
            />
            <Icon name="external-link" className="ml-2" />
          </a>
        </p>
      </div>
    </>
  )
}
export default SignUp
